import React from "react";
import StaticPages from "../views/StaticPages";
import Layout from "../components/Layout/Layout";
import SignUp from "../views/Home/SignUp";

const Article = ({ location }) => {
  return (
    <>
      <div className="bg-bgBlack">
        <Layout>
          <StaticPages pathName={location.pathname} />
          <div id="Signup">
            <SignUp />
          </div>
        </Layout>
      </div>
    </>
  );
};

export default Article;
