import React, { useEffect, useContext } from "react";
import { AppContext } from "../../components/Layout/context";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
import remarkGemoji from "remark-gemoji";
import { useStaticQuery, graphql } from "gatsby";
import slugify from "slugify";
import SEO from "../../components/SEO";

const StaticPages = ({ pathName }) => {
  const slug = pathName
    .replace("/", "")
    .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
  const { lan, setLang } = useContext(AppContext);

  useEffect(() => {
    const languagekey = localStorage.getItem("lankey");
    if (languagekey === null) {
      localStorage.setItem("lankey", "en");
      setLang("en");
    }
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allStrapiPage {
        edges {
          node {
            id
            locale
            slug
            title
            body
          }
        }
      }
    }
  `);
  const posts = data.allStrapiPage?.edges;
  const filteredData = posts.filter(({ node }) => node.locale.includes(lan));

  const filteredPage = filteredData.filter(({ node }) =>
    slugify(node.slug, {
      lower: true,
    }).includes(slug)
  );

  return (
    <div className=" px-4 md:px-0">
      <SEO
        title={`Happen Space | ${filteredPage[0]?.node?.title}`}
        description={filteredPage[0]?.node?.body}
      />
      <h1 className="pt-16 pb-3 text-2xl md:text-5xl font-semibold text-white max-w-5xl mx-auto">
        {filteredPage[0]?.node?.title}
      </h1>
      <div>
        
      </div>
      <div className="max-w-6xl mx-auto px-4 pt-8">
        <div className=" blog-detail  md:px-10 my-12">
          <ReactMarkdown
            children={filteredPage[0]?.node?.body}
            remarkPlugins={[remarkGfm, remarkMath, remarkGemoji]}
            rehypePlugins={[rehypeRaw]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    style={dark}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StaticPages;
